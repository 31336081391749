import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import CenteredDiv from "../../components/centeredDiv/centeredDiv";
import PageLayout from "../../components/pageLayout/pageLayout";

function Winner() {
    const [votes, setVotes] = useState([]);
    const [winner, setWinner] = useState(null);
    const width = window.innerWidth;
    const height = window.innerHeight;
    const [displayButton, setDisplayButton] = useState(true);
    const [tie, setTie] = useState(null);

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=votes`).then(res => {
            setVotes(res.data);
        })
    }, []);

    function getWinner() {
        setDisplayButton(false);
        let arr = votes.map(item => item.vote);
        let counter = Object.create(null);
        arr.forEach(function(word) {
            counter[word] = (counter[word] || 0) + 1;
        });

        let sorted = Object.values(counter).sort();
        let winners = Object.entries(counter).filter(user => user[1] === sorted[sorted.length -1]).map(user => user[0]);
        
        setTimeout(() => {
            setWinner(winners);
        }, 4000)
    };

    return(
        <PageLayout>
            {winner &&
                <ReactConfetti
                    colors={['#b9babe', '#ea4630', '#146b3a']}
                    width={width}
                    height={height}
                />
            }
            {displayButton &&
                <CenteredDiv>
                    <Button
                        sx={{fontSize: '20px', bgcolor: 'white', color: 'red', py: 2, px: 3}}
                        variant='contained'
                        onClick={getWinner}
                    >
                        calculate results
                    </Button>
                </CenteredDiv>
            }
            {!displayButton &&
                <CenteredDiv>
                    <Box sx={{bgcolor: 'rgba(0,0,0,.7)', p: 5, maxWidth: '500px', margin: '0 auto', borderRadius: '5px'}}>
                        
                        {!winner && !tie && 
                            <>
                                <Typography variant='h5' sx={{color: 'white', mb: 2}}>
                                    The winner is...
                                </Typography>
                                <Typography variant='h2' sx={{color: 'red', fontWeight: 'bold'}}>
                                    Drumroll please
                                </Typography>
                            </>
                        }

                        {winner  && 
                            <>
                                <Typography variant='h5' sx={{color: 'white', mb: 2}}>
                                    CONGRATULATIONS
                                </Typography>
                                {winner.map(winner => {
                                    return (
                                        <Typography variant='h2' sx={{color: 'red', fontWeight: 'bold'}}>
                                            {winner}
                                        </Typography>
                                    )
                                })}
                            </>
                        }
                        {}

                    </Box>
                </CenteredDiv>
            }
        </PageLayout>
    )
}

export default Winner;