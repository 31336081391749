import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, Grid, TextField, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {withStyles} from '@mui/styles';
import PageLayout from '../../components/pageLayout/pageLayout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navigation from "../../components/nav/nav";
import axios from "axios";
import CenteredDiv from "../../components/centeredDiv/centeredDiv";
import { Check, CheckCircleOutline, Park } from "@mui/icons-material";

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const styles = {
    input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px black inset"
        }
    }
}

function Vote() {
    const [hasVoted, setHasVoted] = useState(localStorage.getItem('hasVoted'));
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=christmas`).then(res => {
            setUsers(res.data);
        })
    }, [])

    function submit() {
        axios.post('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/save?containerId=votes', {
            vote: selected.name,
            by: localStorage.getItem('user')
        }).then(res => {
            localStorage.setItem('hasVoted', true);
            setHasVoted('true');
        })
    }
    
    return(
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <PageLayout>
                <Box sx={{bgcolor: 'rgba(0,0,0,.6)', zIndex: 6, paddingBottom: '100px', minHeight: '100vh'}}>
                    {!hasVoted &&
                        <>
                            <AppBar position='static'>
                                <Box sx={{width: '100%', py: 2, bgcolor: 'rgba(0,0,0,.7)'}}>
                                    <Typography variant='h5' sx={{width: '100%', textAlign: 'center', mb: 1}}>
                                        <Park sx={{verticalAlign: 'middle', color: 'green'}} /> CAST YOUR VOTE! <Park sx={{verticalAlign: 'middle', color: 'green'}} />
                                    </Typography>
                                    <Typography variant='body2' sx={{width: '100%', textAlign: 'center', color: 'rgba(255, 255, 255, 0.7)', letterSpacing: '.03em'}}>
                                        Select your favorite ugly sweater
                                    </Typography>
                                </Box>
                            </AppBar>
                            <Box component='main'>
                            {users?.map(user => {
                                return (
                                    <Box sx={{width: '100%', padding: '5px', borderTop: '1px solid #ddd'}} key={user.id}>
                                        <Grid container>
                                            <Grid item xs={2} my='auto'>

                                            </Grid>
                                            <Grid item xs={10} my='auto'>
                                                <Box
                                                    component='button'
                                                    sx={{width: '100%', pl: 0, textAlign: 'left', bgcolor: 'transparent', border: '0px', fontSize: '16px', py: 1, color: selected === user ? 'red' : 'white'}}
                                                    onClick={() => setSelected(user)}
                                                >
                                                    {user.name.toUpperCase()}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            })}
                            </Box>
                        </>
                    }
                </Box>
                
                {!hasVoted && selected && users.length > 0 &&
                    <Box sx={{width: '100%', position: 'fixed', bottom: '55px', zIndex: 7}}>
                        <Button 
                            sx={{color: 'white', py: 1, bgcolor: '#8b0000'}} 
                            fullWidth
                            variant='outlined'
                            onClick={submit}
                            disabled={!selected}
                        >
                            vote for {selected?.name}
                        </Button>
                    </Box>
                }
                {hasVoted === 'true' &&
                    <CenteredDiv>
                        <Box sx={{width: '100%', bgcolor: 'rgba(0,0,0,.6)', px: 2, py: 3}}>
                            <CheckCircleOutline sx={{color: '#39ff14', fontSize: '50px', mb: 1}} />
                            <Typography variant='h4' sx={{color: 'white', mb: 2}}>THANK YOU!</Typography>
                            <Typography>Stay tuned to find out who won!</Typography>
                        </Box>
                    </CenteredDiv>
                }
            </PageLayout>
            <Navigation index={2} />
        </ThemeProvider>
    )

}

export default Vote;