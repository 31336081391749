import { Button, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CenteredDiv from '../../components/centeredDiv/centeredDiv';
import PageLayout from '../../components/pageLayout/pageLayout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {withStyles} from '@mui/styles';
import Navigation from '../../components/nav/nav';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const styles = {
    input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px black inset"
        }
    }
}

const DarkTextField = withStyles(styles)(props => {
    return <TextField {...props} />
})

function GetNumber() {
    const [number, setNumber] = useState(null);
    const [email, setEmail] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let num = localStorage.getItem('number');
        if(!num) {
            axios.get('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=christmas').then(res => {
                setUsers(res.data);
            })
        }

        else {
            setNumber(num);
        }
    }, [])

    function getNumber() {
        let user = users.filter(user => user.email.toLowerCase().trim() === email.toLowerCase().trim())[0];
        setNumber(user.number);
        localStorage.setItem('number', user.number);
        localStorage.setItem('user', user.name);
    }

    function handleChange(e) {
        setEmail(e.target.value);
    }

    return(
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <PageLayout>
                <CenteredDiv>
                    <Box sx={{bgcolor: 'rgba(0,0,0,.6)', px: 2, py: 3, borderRadius: '5px'}}>
                        {!number &&
                            <>
                                <Typography sx={{mb:2}}>Enter your email below to get a number</Typography>
                                <DarkTextField
                                    size='small'
                                    fullWidth
                                    label='Email'
                                    value={email}
                                    onChange={handleChange}
                                    sx={{borderColor: 'white', mb: 3, color: 'white', bgcolor: 'transparent'}}
                                />
                                <Button
                                    sx={{borderColor: 'white', color: 'white'}}
                                    variant='outlined'
                                    fullWidth
                                    onClick={getNumber}
                                >
                                    get my number
                                </Button>
                            </>
                        }
                        {number &&
                            <>
                                <Typography variant='h5' sx={{color: 'white', mb: 1}}>Your number is</Typography>
                                <Typography variant='h1' sx={{color: 'white', fontWeight: 'bold'}}>{number}</Typography>
                            </>
                        }
                    </Box>
                </CenteredDiv>
                <Navigation index={1} />
            </PageLayout>
        </ThemeProvider>
    )
}

export default GetNumber;