import { Box } from "@mui/material";
import React from "react";
import bg from '../../images/bg.jpg';
import Navigation from "../nav/nav";
import './style.css';

function PageLayout({ children }) {

    return(
        <>
            <div className="bg">
                { children }
            </div>  
            <div className='overlay' />  
        </>
    )
}

export default PageLayout;