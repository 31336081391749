import React from "react";
import './style.css';

function CenteredDiv({ children }) {
    return(
        <div className="centered">
            { children }
        </div>
    )
}

export default CenteredDiv;