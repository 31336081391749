import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CenteredDiv from "../../components/centeredDiv/centeredDiv";
import PageLayout from "../../components/pageLayout/pageLayout";
import logo from '../../images/logo.png';

function Landing() {
    const navigate = useNavigate();

    return(
        <>
            <PageLayout>
                <CenteredDiv>
                    <Box sx={{width: '80vw', bgcolor: 'rgba(0,0,0,.7)', px: 2, py: 3, borderRadius: '5px'}}>
                        <img src={logo} alt='' style={{width: '130px'}} />
                        {/* <Typography variant='h5' sx={{color: 'white', fontFamily: 'Raleway'}}>WELCOME TO OUR</Typography> */}
                        <Typography variant='h2' sx={{color: 'red', fontFamily: 'geraldine'}}>Christmas Party</Typography>
                        <Typography variant='h4' sx={{mb: 2, color: 'white', fontFamily: 'Raleway'}}>2022</Typography>
                        <Button
                            sx={{color: 'white', borderColor: 'white'}}
                            variant='outlined'
                            onClick={() => navigate('/mynumber')}
                            fullWidth
                        >
                            continue
                        </Button>
                    </Box>
                </CenteredDiv> 
            </PageLayout>
        </>
    )
}

export default Landing;