import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import './style.css';
import { Box } from "@mui/system";
import CenteredDiv from "../../components/centeredDiv/centeredDiv";

var XLSX = require('xlsx');

function Admin() {
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [winner, setWinner] = useState(null);

    const arr = [
        {
            "name": "Brett Estok",
            "email": "EstokB@transblue.org",
            "number": 30
        },
        {
            "name": "Carlina McBryde",
            "email": "McBrydeC@transblue.org",
            "number": 13
        },
        {
            "name": "Chavon Cotterell",
            "email": "CotterellC@transblue.org",
            "number": 5
        },
        {
            "name": "Chris Lambert",
            "email": "LambertC@transblue.org",
            "number": 28
        },
        {
            "name": "Corrie Ash",
            "email": "AshC@transblue.org",
            "number": 19
        },
        {
            "name": "Dave Wescott",
            "email": "wescottd@transblue.org",
            "number": 21
        },
        {
            "name": "David Vetter",
            "email": "VetterD@transblue.org",
            "number": 29
        },
        {
            "name": "Dena Kehler",
            "email": "KehlerD@transblue.org",
            "number": 15
        },
        {
            "name": "Dylan Moses",
            "email": "MosesD@transblue.org",
            "number": 32
        },
        {
            "name": "Esteban Tobon",
            "email": "TobonE@transblue.org",
            "number": 3
        },
        {
            "name": "Jim Wescott",
            "email": "Jim.wescott@transblue.org",
            "number": 22
        },
        {
            "name": "Jonathan McQuade",
            "email": "McQuadeJ@transblue.org",
            "number": 7
        },
        {
            "name": "Kayla Peterson",
            "email": "kaypet@transblue.org",
            "number": 18
        },
        {
            "name": "Kellie Ramsey",
            "email": "kelram@transblue.org",
            "number": 16
        },
        {
            "name": "Larry Hyatt",
            "email": "HyattL@transblue.org",
            "number": 14
        },
        {
            "name": "Madison Goodman",
            "email": "GoodmanM@transblue.org",
            "number": 33
        },
        {
            "name": "Mary Fowler",
            "email": "FowlerM@transblue.org",
            "number": 11
        },
        {
            "name": "Megan Comins",
            "email": "CominsM@transblue.org",
            "number": 26
        },
        {
            "name": "Paul Comer",
            "email": "Comerp@transblue.org",
            "number": 10
        },
        {
            "name": "Philip Mendez",
            "email": "MendezP@transblue.org",
            "number": 4
        },
        {
            "name": "Radhika Raj",
            "email": "RajR@transblue.org",
            "number": 23
        },
        {
            "name": "Ralph Helm",
            "email": "ralhel@transblue.org",
            "number": 20
        },
        {
            "name": "Randy Seek",
            "email": "SeekR@transblue.org",
            "number": 34
        },
        {
            "name": "Rich Reynolds",
            "email": "ricrey@transblue.org",
            "number": 17
        },
        {
            "name": "Sarah Carter",
            "email": "CarterS@transblue.org",
            "number": 31
        },
        {
            "name": "Shane McGowan",
            "email": "McGowanS@transblue.org",
            "number": 12
        },
        {
            "name": "Sheri Fleck",
            "email": "SheFle@transblue.org",
            "number": 9
        },
        {
            "name": "Susan Lemery",
            "email": "SusLem@transblue.org",
            "number": 27
        },
        {
            "name": "Todd Chapman",
            "email": "ChapmanT@transblue.org",
            "number": 6
        },
        {
            "name": "Vince Ortiz",
            "email": "ortizv@transblue.org",
            "number": 8
        },
        {
            "name": "Virginia Nelson",
            "email": "NelsonV@transblue.org",
            "number": 25
        },
        {
            "name": "Lisa ",
            "email": "lisa.Barnes@condoshield.org",
            "number": 24
        },
        {
            "name": "Pastor D",
            "email": "David@mbridge.global",
            "number": 1
        },
        {
            "name": "Brady",
            "email": "Brady@mbridge.global",
            "number": 2
        }
    ]

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=christmas`).then(res => {
            setUsers(res.data);
            setAllUsers(res.data);
        })
    }, []);

    function pickWinner() {
        let random = users[Math.floor(Math.random() * users.length)].number;
        setUsers(users.filter(user => user.number !== random));
        setWinner(random);

        setTimeout(() => {
            setWinner(null);
        }, 20000)
    }

    function assignNumber() {
        arr.forEach(user => {
            axios.post('https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/save?containerId=christmas', user).then(res => {
                console.log(res)
            })
        })
    }

    function uploadFile(e) {
        let reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'array'});
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);

            console.log(json);

        }

        reader.readAsArrayBuffer(e.target.files[0])
    }

    return(
        <Box sx={{width: '100%', height: '100vh'}}>
           {!winner &&
           
                <CenteredDiv>
                    <Button
                        onClick={pickWinner}
                    >
                        pick winner
                    </Button>
                </CenteredDiv>
           }

            {winner &&
                <video controls autoPlay src={`${process.env.PUBLIC_URL}/animations/${winner}.mp4`} />
            }
        </Box>
    )

}

export default Admin;