import { Routes, Route } from 'react-router-dom';
import Landing from './pages/landing/landing';
import GetNumber from './pages/getNumber/getNumber';
import Vote from './pages/vote/vote';
import Admin from './pages/admin/admin';
import './App.css';
import Winner from './pages/winner/winner';

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Landing />} />
      <Route exact path='/mynumber' element={<GetNumber />} />
      <Route exact path='/vote' element={<Vote />} />
      <Route exact path='/admin' element={<Admin />} />
      <Route exact path='/admin/winner' element={<Winner />} />
    </Routes>
  );
}

export default App;