import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Home, Numbers, Poll } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Navigation({ index }) {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: '100%', zIndex: 20, position: 'fixed', bottom: 0, left: 0 }}>
            <BottomNavigation
                showLabels
                value={index}
            >
                <BottomNavigationAction 
                    label="Home" 
                    icon={<Home />}
                    onClick={() => navigate('/')}
                />
                <BottomNavigationAction 
                    label="My Number" 
                    icon={<Numbers />} 
                    onClick={() => navigate('/mynumber')}
                />
                <BottomNavigationAction 
                    label="Vote" 
                    icon={<Poll />} 
                    onClick={() => navigate('/vote')}
                />
            </BottomNavigation>
        </Box>
    );
}